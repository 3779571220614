import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const app = {
  state: {
    isLoading: false,

    // RESI
    report_resi: "",

    // ONGKIR
    list_from_city: [],
    list_to_city: [],
    form_check_price: {
      fromCityId: "",
      toCityId: "",
      weight: "",
      volume: "",
    },
    report_ongkir: "",
  },
  mutations: {
    SET_LOADING_APP(state, payload) {
      state.isLoading = payload;
    },
    SET_RESULT_CHECK_RESI(state, payload) {
      state.report_resi = payload;
    },
    SET_LIST_FROM_CITY_CHECK_PRICE(state, payload) {
      state.list_from_city = payload;
    },
    SET_LIST_TO_CITY_CHECK_PRICE(state, payload) {
      state.list_to_city = payload;
    },
    SET_FORM_CHECK_PRICE(state, payload) {
      state.form_check_price[payload.key] = payload.value;
    },
    SET_RESULT_CHECK_PRICE(state, payload) {
      state.report_ongkir = payload;
    },
  },
  actions: {
    async CheckResi(context, resi) {
      context.commit("SET_LOADING_APP", true);
      try {
        const result = await axios({
          url: `${apiUrl}/guest/check-resi/${resi}`,
          method: "GET",
        });

        context.commit("SET_RESULT_CHECK_RESI", result.data.data);

        return true;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_LOADING_APP", false);
      }
    },
    async GetListFromCity(context) {
      context.commit("SET_LOADING_APP", true);
      try {
        const result = await axios({
          url: `${apiUrl}/check-price/from-city`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_LIST_FROM_CITY_CHECK_PRICE", result.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        context.commit("SET_LOADING_APP", false);
      }
    },
    async GetListToCityCheckPrice(context, { id, keyword }) {
      context.commit("SET_LOADING_APP", true);
      try {
        const result = await axios({
          url: `${apiUrl}/check-price/to-city/${id}`,
          method: "GET",
          params: { keyword },
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_LIST_TO_CITY_CHECK_PRICE", result.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        context.commit("SET_LOADING_APP", false);
      }
    },
    async CheckPrice(context) {
      context.commit("SET_LOADING_APP", true);
      try {
        const data = context.state.form_check_price;
        const weight = parseFloat(data.weight)
        const volume = parseFloat(data.volume)

        const result = await axios({
          url: `${apiUrl}/check-price`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: {
            fromCityId: data.fromCityId,
            toCityId: data.toCityId,
            weight: weight > volume ? weight : volume,
          },
        });

        context.commit("SET_RESULT_CHECK_PRICE", result.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        context.commit("SET_LOADING_APP", false);
      }
    },
  },
};

export default app;
