<template>
  <v-app class="wrapper">
    <v-main class="flex align-center justify-center">
      <div class="my-10" style="max-width: 800px; margin: auto">
        <v-card>
          <div class="d-flex justify-center" style="background: #001f3f">
            <img :src="require('@/assets/logo.png')" width="80" alt="" />
          </div>
          <v-tabs v-model="tab" centered background-color="#001f3f" dark>
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
          <div class="pa-4">
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in items" :key="item">
                <v-card color="basil" flat>
                  <br />
                  <CekResi v-if="item == items[0]" />
                  <CekOngkir v-if="item == items[1]" />
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "HomePage",
  components: {
    CekResi: () => import("@/components/CekResi.vue"),
    CekOngkir: () => import("@/components/CekOngkir.vue"),
  },
  data: () => ({
    tab: null,
    items: ["Cek Resi", "Cek Ongkir"],
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  }),
};
</script>
